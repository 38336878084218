import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { groups } from "../recommendation/GroupFilter";

const Interventions = ({ width, columns, currentIntentSelected }) => {
  return (
    <div
      id="interventions-row"
      className="interventions-row flex-row items-center"
      style={{ width: width }}
    >
      {currentIntentSelected !== "all" &&
        columns &&
        columns[0]?.name !== _.camelCase(groups[1]) && // do not duplicate columns from main tabs, using index 1 because at index 0 we have `all` key
        _.map(columns, (column, idx) => (
          <div className="rmt-header-cell h-full" key={`${idx}-${_.camelCase(column.name)}`}>
            {column.name}
          </div>
        ))}
    </div>
  );
};

const RMTHeader = ({
  columnWidth,
  headerWidth,
  columns,
  onChangeIntentSelected,
  currentIntentSelected,
  interventionsRowRef,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="flex flex-row items-center interventions overflow-hidden"
        style={{ width: headerWidth }}
      >
        {_.map(_.without(groups, "all"), (group) => {
          const groupCamelCased = _.camelCase(group);
          return (
            <div
              onClick={onChangeIntentSelected(groupCamelCased)}
              key={`header-${group}`}
              className={`interventions-group ${
                currentIntentSelected === groupCamelCased ? "current" : ""
              }`}
              style={{ width: `${columnWidth}px` }}
            >
              {t(`groups.${group}`)}
            </div>
          );
        })}
      </div>

      <div
        className="interventions-row-container"
        ref={interventionsRowRef}
        style={{ width: headerWidth }}
      >
        <Interventions
          width={headerWidth}
          columns={columns}
          currentIntentSelected={currentIntentSelected}
        />
      </div>
    </>
  );
};

RMTHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })).isRequired,
};

export default RMTHeader;
