import React from "react";
import { useTranslation } from "react-i18next";
import InstructionModal from "../common/InstructionModal";
import WhatWeAreCurrentlyWorkingOn from "../common/WhatWeAreCurrentlyWorkingOn";

const WhatWeAreCurrentlyWorkingOnModal = () => {
  const { t } = useTranslation();
  return (
    <div className="text-right text-covid-100">
      <InstructionModal
        triggerLabel={
          <span className="hover:opacity-50">{t("labels.what_we_are_currently_working_on")}</span>
        }
      >
        <h1 className="text-xl mb-6 font-semibold">
          {t("labels.what_we_are_currently_working_on")}
        </h1>
        <WhatWeAreCurrentlyWorkingOn contentClassName="text-left" />
      </InstructionModal>
    </div>
  );
};

export default WhatWeAreCurrentlyWorkingOnModal;
