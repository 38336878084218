import React, { FC } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

type RecommendationType =
  | undefined
  | ""
  | "recommend-against"
  | "conditional"
  | "suggest-either"
  | "suggest-against"
  | "strong-for"
  | "suggest-for"
  | "recommend-for";

interface IRecommendationIcon {
  textClasses?: string;
  recommendationType?: RecommendationType;
  recommendationText: string;
}

const RecommendationIcon: FC<IRecommendationIcon> = ({
  textClasses,
  recommendationType,
  recommendationText,
}) => {
  return (
    <div className="flex flex-row items-center leading-none">
      {!_.isEmpty(recommendationType) && (
        <div className={`recommendation-icon ${recommendationType}`} />
      )}
      <div className={`text-md ${textClasses}`}>{recommendationText}</div>
    </div>
  );
};

RecommendationIcon.propTypes = {
  textClasses: PropTypes.string,
  recommendationType: PropTypes.oneOf<RecommendationType>([
    "",
    "recommend-against",
    "conditional",
    "suggest-either",
    "suggest-against",
    "strong-for",
    "suggest-for",
    "recommend-for",
  ]),
  recommendationText: PropTypes.string.isRequired,
};

RecommendationIcon.defaultProps = {
  textClasses: "",
  recommendationType: undefined,
};

export default RecommendationIcon;
