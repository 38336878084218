import React from "react";
import { useTranslation } from "react-i18next";
import searchInstructionImg from "../../assets/search-instruction.svg";

const SearchInstructions = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1 className="text-xl mb-6 font-semibold">{t("labels.search_instructions")}</h1>
      <div>
        <img src={searchInstructionImg} alt={t("labels.search_instructions")} />
      </div>
    </div>
  );
};

export default SearchInstructions;
