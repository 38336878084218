import _ from "lodash";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import epLogo from "../../assets/logos/ep-logo.svg";
import gradeProLogo from "../../assets/logos/gradepro-logo.svg";
import mcMasterLogo from "../../assets/logos/mcmaster-logo.svg";
import {
  twitterLink,
  glossaryLink,
  surveyLink,
  contactMail,
  getItGlossaryLink,
  linkedIn,
  facebook,
  instagramLink,
} from "../../lib/constants";
import { useHandleClickOutside } from "../../lib/custom_hooks";
import { DisclaimerShort } from "./DisclaimerShort";

const HamburgerMenu = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useHandleClickOutside(menuRef, () => setMenuOpen(false));

  const goTo = (page: string) => () => {
    history.push(page);
    setMenuOpen(false);
  };

  return (
    <div ref={menuRef} className="h-6 relative ml-auto mt-1 self-start justify-end hamburger-menu">
      <button
        className={`open-menu block ${menuOpen ? "fixed open" : "absolute closed"}`}
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      />

      {menuOpen && (
        <div className="menu-container">
          <div className="flex flex-col h-full">
            <div className="flex flex-col flex-grow">
              <div className="py-5 mx-5 glossary-links">
                <button
                  className="text-left block py-2 font-semibold hover:opacity-50"
                  onClick={goTo("/about")}
                >
                  {t(`app.aboutCovid19`)}
                </button>
                <button
                  className="text-left block py-2 font-semibold hover:opacity-50"
                  onClick={goTo("/guidelines-on-change-of-care")}
                >
                  {t("labels.guidelinesOnChangeOfCare")}
                </button>
                <button
                  className="text-left block py-2 font-semibold hover:opacity-50"
                  onClick={goTo("/our-publications")}
                >
                  {t("labels.ourPublications")}
                </button>
                <button
                  className="text-left block py-2 font-semibold hover:opacity-50"
                  onClick={goTo("/experts-bureau")}
                >
                  {t("labels.expertsBureau")}
                </button>
                <button
                  className="text-left block py-2 font-semibold hover:opacity-50"
                  onClick={goTo("/glossary")}
                >
                  {t("labels.glossary")}
                </button>
                <a
                  className="block py-2 font-semibold hover:opacity-50"
                  href={glossaryLink}
                  target="blank"
                  rel="noopener noreferrer"
                >
                  {t("labels.grade_handbook_glossary")}
                </a>
                <a
                  className="block py-2 font-semibold hover:opacity-50"
                  href={getItGlossaryLink}
                  target="blank"
                  rel="noopener noreferrer"
                >
                  {t("labels.get_it_glossary")}
                </a>
                <a
                  className="block py-2 font-semibold hover:opacity-50"
                  href={surveyLink}
                  target="blank"
                  rel="noopener noreferrer"
                >
                  {t("labels.survey")}
                </a>
              </div>
              {!_.isEmpty(twitterLink) && (
                <div className="pt-5 mx-5">
                  <a
                    className="social-link twitter"
                    href={twitterLink}
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    {t("labels.visit_our_twitter")}
                  </a>
                </div>
              )}
              {!_.isEmpty(linkedIn) && (
                <div className="pt-5 mx-5">
                  <a
                    className="social-link linkedin"
                    href={linkedIn}
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    {t("labels.visit_our_linkedin")}
                  </a>
                </div>
              )}
              {!_.isEmpty(facebook) && (
                <div className="pt-5 mx-5">
                  <a
                    className="social-link facebook"
                    href={facebook}
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    {t("labels.visit_our_facebook")}
                  </a>
                </div>
              )}
              {!_.isEmpty(instagramLink) && (
                <div className="pt-5 mx-5">
                  <a
                    className="social-link instagram"
                    href={instagramLink}
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    {t("labels.visit_our_instagram")}
                  </a>
                </div>
              )}
              <a
                href={`mailto:${contactMail}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-right see_more mr-6"
              >
                {t("labels.contact_us")}
              </a>
              <div className="px-6 text-xs mt-6">
                <DisclaimerShort withSeeMoreBtn />
              </div>
              <div className="logos flex flex-col flex-grow justify-end">
                <a
                  className="block mb-6 flex justify-end mr-6"
                  href="https://www.mcmaster.ca/"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  <img src={mcMasterLogo} alt="McMaster" />
                </a>
                <a
                  className="block mb-12 flex justify-end mr-6"
                  href="https://evidenceprime.com/"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  <img src={epLogo} alt="EvidencePrime" />
                </a>
                <a
                  className="block mb-6 flex justify-end mr-6"
                  href="https://gradepro.org/"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  <img src={gradeProLogo} alt="GRADEpro" />
                </a>
              </div>
            </div>

            <div className="text-center sm:text-left">
              <button className="close-menu-arrow" onClick={() => setMenuOpen(false)} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

HamburgerMenu.propTypes = {};

export default HamburgerMenu;
