import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { noteShape, referenceShape } from "../../lib/data_shapes";
import CustomTable from "../common/CustomTable";
import ExpandableCard from "../common/ExpandableCard";
import References from "./References";

const Notes = ({ notes, references, toggleRef }) => {
  const { t } = useTranslation();
  if (!notes) {
    return null;
  }

  const rows = _.chain(notes)
    .groupBy("@type")
    .map((n) => ({
      label: t(`labels.${_.snakeCase(_.first(n)["@type"])}`),
      value: _.map(n, (v, idx) => (
        <div className="mb-2" key={idx} dangerouslySetInnerHTML={{ __html: v.value }} />
      )),
    }))
    .value();

  return _.isEmpty(rows) && _.isEmpty(references) ? null : (
    <ExpandableCard title={t("labels.good_practice_statement_criteria")} toggleRef={toggleRef}>
      <CustomTable rows={rows} />
      <References references={references} />
    </ExpandableCard>
  );
};

Notes.propTypes = {
  notes: PropTypes.arrayOf(noteShape),
  references: PropTypes.arrayOf(referenceShape),
};

Notes.defaultProps = {
  notes: null,
  references: [],
};

export default Notes;
