import * as React from "react";
import ExpandableCard from "../common/ExpandableCard";
import { useTranslation } from "react-i18next";

interface IExpertComment {
  expertComment: string;
  toggleRef: HTMLDivElement;
}

export default function ExpertComment({ expertComment, toggleRef }: IExpertComment) {
  const { t } = useTranslation();
  return (
    <ExpandableCard title={t("labels.expertComment")} toggleRef={toggleRef}>
      <div dangerouslySetInnerHTML={{ __html: expertComment }} />
    </ExpandableCard>
  );
}
