import React, { FC } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const Intents: FC<{ intents: string[] }> = ({ intents }) => {
  const { t } = useTranslation();

  const preparedIntents = _.chain(intents)
    .without("all")
    .map((intent) => t(`recommendation.intents.${intent}`))
    .value();

  return !_.isEmpty(preparedIntents) ? (
    <div className="my-3">
      {t("recommendation.intent")}
      {": "}
      <span className="font-semibold">{preparedIntents.join(", ")}</span>
    </div>
  ) : null;
};

Intents.propTypes = {
  intents: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default Intents;
