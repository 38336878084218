import PropTypes from "prop-types";
import React from "react";

const GridFixedColumn = ({ className, children, gridFixedColRef }) => (
  <div className={className} ref={gridFixedColRef}>
    {children}
  </div>
);

GridFixedColumn.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

GridFixedColumn.defaultProps = {
  className: "",
};

export default GridFixedColumn;
