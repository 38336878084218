import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomTable, { IRow } from "../common/CustomTable";
import ExpandableCard from "../common/ExpandableCard";

interface ISummaryOfChoices {
  assessmentSummary: {
    sections: {
      [key: string]: string;
    };
    sectionsOrder: string[];
  };
  toggleRef: HTMLDivElement;
}

const SummaryOfChoices = ({ assessmentSummary, toggleRef }: ISummaryOfChoices) => {
  const { t } = useTranslation();
  if (!assessmentSummary) return null;
  const { sections, sectionsOrder } = assessmentSummary;
  const hasAnyValue = _.some(sections, (section) => !_.isEmpty(section));
  if (!hasAnyValue) return null;
  const rows = _.map(sectionsOrder, (sectionName) => {
    if (!_.isEmpty(sections[sectionName])) {
      return {
        label: t(`recommendation.assessmentSummary.${sectionName}.title`),
        value: t(`recommendation.assessmentSummary.${sectionName}.values.${sections[sectionName]}`),
      } as IRow;
    }
  }) as IRow[];

  return (
    <ExpandableCard
      title={t("labels.summary_of_judgements")}
      tooltip={t("tooltips.summaryOfJudgements")}
      toggleRef={toggleRef}
    >
      <CustomTable rows={rows} />
    </ExpandableCard>
  );
};

SummaryOfChoices.propTypes = {
  assessmentSummary: PropTypes.shape({
    sections: PropTypes.objectOf(PropTypes.string).isRequired,
    sectionsOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default SummaryOfChoices;
