import PropTypes from "prop-types";
import React, { FC } from "react";
import _ from "lodash";
import { Trans, useTranslation } from "react-i18next";
import { LANGUAGES } from "./LanguageSwitcher";
import { useHistory, useLocation } from "react-router";

type TLanguages = typeof LANGUAGES[number];

const WhatWeAreCurrentlyWorkingOn: FC<{ contentClassName?: string }> = ({ contentClassName }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const selectedLanguage = _.includes(LANGUAGES, i18n.language) ? i18n.language : "en";

  const changeLanguage = (lang: TLanguages) => {
    i18n.changeLanguage(lang).then(() => {
      params.set("lng", lang);
      history.push({ search: params.toString() });
    });
  };
  return (
    <div>
      <div className="mx-3">
        <div className={contentClassName}>
          <div className="font-semibold text-center text-covid-100 py-3">
            {t("labels.frenchPortal")}
          </div>
          <Trans i18nKey="detailsOfRecommendationPage.frenchPortal">
            <p className="mb-2">
              Our partners at the American University of Beirut GRADE Center (Lebanon), Cochrane
              Canada Francophone at Université Laval (Canada) and eBASE Africa (Cameroon) are
              leading on the translation of selected recommendations from the eCOVID-19 RecMap &
              Gateway to contextualization to French.
            </p>
          </Trans>
          <button
            className="text-covid-100 hover:opacity-75 font-semibold"
            onClick={() => changeLanguage(selectedLanguage === "en" ? "fr" : "en")}
          >
            {selectedLanguage === "en" ? t("actions.switch_to_fr") : t("actions.switch_to_en")}
          </button>
        </div>
      </div>
    </div>
  );
};

WhatWeAreCurrentlyWorkingOn.propTypes = {
  contentClassName: PropTypes.string,
};

WhatWeAreCurrentlyWorkingOn.defaultProps = {
  contentClassName: undefined,
};

export default WhatWeAreCurrentlyWorkingOn;
