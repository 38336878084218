import _ from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetApi } from "../lib/api";
import { parseTable } from "../lib/helpers";
import Box from "./common/Box";
import LargeSpinner from "./common/LargeSpinner";

type TableRow = {
  name: string;
  organization: string;
  role: string;
};

export const Team = () => {
  const { t } = useTranslation();
  // @ts-ignore
  const [{ data, isLoading }] = useGetApi(`/team`);
  // @ts-ignore
  const table = _.first(data)?.body;
  const parsedTable: TableRow[] | null = useMemo(
    () => parseTable(table) as TableRow[] | null,
    [table]
  );

  if (isLoading) {
    return <LargeSpinner />;
  }

  return (
    <div className="w-full max-w-screen-lg relative mx-auto mb-6">
      <div className="w-full flex flex-row align-center justify-between mb-2">
        <h1 className={`pl-6 pt-2 text-2xl text-covid-100 font-normal`}>
          {t("labels.investigators")}
        </h1>
      </div>
      <Box className="p-5 bg-white mb-6">
        <div className="w-full p-4 flex flex-row flex-wrap justify-center align-center team-table">
          {_.map(parsedTable, ({ name, role, organization }, idx) => {
            return (
              <div className="flex flex-row w-full" key={idx}>
                <div className="cell p-3 font-semibold text-covid-100 w-64">{name}</div>
                <div className="cell p-3 w-64">{role}</div>
                <div className="cell p-3 flex-1">{organization}</div>
              </div>
            );
          })}
        </div>
      </Box>
    </div>
  );
};
