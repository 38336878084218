import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../assets/covid_white.svg";
import {
  contactMail,
  evidenceprimeLink,
  facebook,
  glossaryLink,
  linkedIn,
  surveyLink,
  twitterLink,
  instagramLink,
} from "../../lib/constants";
import { DisclaimerShort } from "../common/DisclaimerShort";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="bg-gray-200 py-5">
      <div className="max-w-screen-md text-center mx-auto text-gray-500 text-xs mb-6">
        <DisclaimerShort withSeeMoreBtn />
      </div>
      <div className="max-w-screen-lg mx-auto">
        <div className="flex flex-row">
          <Link className="ml-2 md:ml-0 flex flex-grow flex-row items-center" to="/">
            <img src={logo} className="h-12 tailwind-hidden sm:block" alt="logo" />
            <img src={logo} className="h-8 block sm:hidden" alt="logo" />
            <span className="ml-3 text-2xl text-white font-medium">{t("app.title")}</span>
          </Link>
          <div className="py-5 ml-5">
            <a className="twitter" href={twitterLink} target="blank" rel="noopener noreferrer">
              <span className="logo white" />
            </a>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="flex-2">
            <div className="py-5 mx-5 text-white">
              <Link className="block py-2 hover:opacity-50" to="/about">
                {t("labels.aboutRecMap")}
              </Link>
              <Link className="block py-2 hover:opacity-50" to="/glossary">
                {t("labels.glossary")}
              </Link>
              <a
                className="block py-2 hover:opacity-50"
                href={glossaryLink}
                target="blank"
                rel="noopener noreferrer"
              >
                {t("labels.grade_handbook_glossary")}
              </a>
              <a
                className="block py-2 hover:opacity-50"
                href={surveyLink}
                target="blank"
                rel="noopener noreferrer"
              >
                {t("labels.survey")}
              </a>
            </div>
          </div>
          <div className="flex flex-grow">
            <div>
              <div className="py-5 text-white w-full">
                <a href={`mailto:${contactMail}`} target="_blank" rel="noopener noreferrer">
                  {t("labels.contact_us")}
                </a>
              </div>
              {!_.isEmpty(twitterLink) && (
                <div className="pt-2">
                  <a
                    className="social-link social-link--footer twitter white"
                    href={twitterLink}
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    {t("labels.visit_our_twitter")}
                  </a>
                </div>
              )}
              {!_.isEmpty(linkedIn) && (
                <div className="pt-2">
                  <a
                    className="social-link social-link--footer linkedin white"
                    href={linkedIn}
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    {t("labels.visit_our_linkedin")}
                  </a>
                </div>
              )}
              {!_.isEmpty(facebook) && (
                <div className="pt-2">
                  <a
                    className="social-link social-link--footer facebook white"
                    href={facebook}
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    {t("labels.visit_our_facebook")}
                  </a>
                </div>
              )}
              {!_.isEmpty(instagramLink) && (
                <div className="pt-2">
                  <a
                    className="social-link social-link--footer instagram white"
                    href={instagramLink}
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    {t("labels.visit_our_instagram")}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mx-auto mt-5 text-center">
          <a
            className="block mx-auto text-white font-semibold"
            href={evidenceprimeLink}
            target="blank"
            rel="noopener noreferrer"
          >
            {t("labels.crafted_by_evidenceprime")}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
