import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { TPlus, TProgress, TProgressValues } from "../../lib/types";
import ExternalLink from "./ExternalLink";

const LINKS_MAP: { [key: string]: string } = {
  personalCharacteristics: "63e43c9a24e40300081c637b",
  featuresOfRelationships: "63e43cc4fe5dce00082cda42",
  timeDependentRelationships: "63e43cf3fe5dce00082cda53",
  placeOfResidence: "6058ad1469c00e22e2f3dc97",
  raceEthnicityCultureOrLanguage: "605cb7ec3d051528c9894f0e",
  occupation: "605cb8993d051528cb8a6c03",
  genderOrSex: "605cbb5635525846d0f7fb73",
  religion: "60743d6b69c00e0337e5e191",
  education: "6095846b69c00e615303b937",
  ses: "6074540e3d0515683ddff409",
  socialCapital: "607457db69c00e0337e5e9ef",
};

interface IEquityLinks {
  url: string;
  progress: TProgress;
}

const EquityLinks = ({ url, progress }: IEquityLinks) => {
  return (
    <div className="gradient-links-bg py-1 px-2 sm:py-3 sm:px-3 h-full">
      <Links baseUrl={url} labelKey="progress" progressOrPlus={progress.progress} />
      <Links baseUrl={url} labelKey="plus" progressOrPlus={progress.plus} />
    </div>
  );
};

const Links = ({
  baseUrl,
  progressOrPlus,
  labelKey,
}: {
  baseUrl: string;
  labelKey: "plus" | "progress";
  progressOrPlus: TPlus[] | TProgressValues[];
}) => {
  const { t } = useTranslation();
  return !_.isEmpty(progressOrPlus) ? (
    <div className="links">
      {_.map(progressOrPlus as string[], (valueKey: string) => {
        return (
          <ExternalLink
            key={valueKey}
            className="px-0 block"
            url={`${baseUrl}&specific_contextual_factors=${LINKS_MAP[valueKey]}`}
          >
            {t(`recommendation.fieldValues.${labelKey}.${valueKey}`)}
          </ExternalLink>
        );
      })}
    </div>
  ) : null;
};

export default EquityLinks;
