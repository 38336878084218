import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { map } from "lodash";
import Box from "../common/Box";
import amsterdamUMCLogo from "../../assets/logos/AUMC.svg";
import cihrLogo from "../../assets/logos/cihr-logo.svg";
import cochraneCanadaLogo from "../../assets/logos/cochrane-canada-logo.svg";
import evidencePrimeLogo from "../../assets/logos/evidence-prime-logo.svg";
import mcMasterLogo from "../../assets/logos/mcmaster-logo.svg";
import niphLogo from "../../assets/logos/niph-logo.svg";
import whoLogo from "../../assets/logos/who_paho.svg";
import apaaciLogo from "../../assets/logos/apaaci-logo.png";
import aubLogo from "../../assets/logos/aub-logo.png";
import australiaJBILogo from "../../assets/logos/australia-jbi-logo.png";
import barcelonaSantPauLogo from "../../assets/logos/barcelona.jpg";
import biggLogo from "../../assets/logos/bigg-logo.png";
import cochraneDeutschlandLogo from "../../assets/logos/cochrane-deutschland-logo.png";
import cochraneSouthAfricaLogo from "../../assets/logos/cochrane-south-africa-logo.png";
import czechCochraneLogo from "../../assets/logos/cochrane-czech-logo.png";
import epistemonikosLogo from "../../assets/logos/epistemonikos-logo.png";
import germanyFreiburgLogo from "../../assets/logos/germany_freiburg.jpg";
import gesiLogo from "../../assets/logos/gesi-logo.png";
import ginLogo from "../../assets/logos/gin-logo2.png";
import gradeMasarykLogo from "../../assets/logos/grade-masaryk-logo.png";
import iberoamericaCochrane from "../../assets/logos/iberoamerica_cochrane.png";
import jbiLogo from "../../assets/logos/jbi-logo.png";
import peterboroughPublicHealthLogo from "../../assets/logos/peterborough-public-health.png";
import loveLogo from "../../assets/logos/love-logo.png";
import munimedLogo from "../../assets/logos/munimed-logo.png";
import niceLogo from "../../assets/logos/nice-logo.png";
import pgimerLogo from "../../assets/logos/PGIMER_Chandigath_logo.png";
import kumcLogo from "../../assets/logos/KUMC-logo.png";
import agreeLogo from "../../assets/logos/agreeLogo.jpg";
import MGCLogo from "../../assets/logos/MGD_Cochrane_Canada_Centre_Logo.png";
import canadaFrancophoneLogo from "../../assets/logos/canada-francophone-logo.png";
import yealthLogo from "../../assets/logos/yealth-logo.png";
import ACPLogo from "../../assets/logos/b-acp-logo.png";
import hunimedLogo from "../../assets/logos/logo-hunimed.jpg";
import CADTHLogo from "../../assets/logos/CADTH-ACMTS-logo.jpg";
import lanzhouUniversityLogo from "../../assets/logos/lanzhouUniversityLogo.gif";
import sociedadePortuguesa from "../../assets/logos/sociedade-portuguesa.png";
import craScr from "../../assets/logos/cra-scr.png";
import archeLogo from "../../assets/logos/ARCHE w-tag.jpg";
import cochraneChildHealth from "../../assets/logos/Cochrane_ChildHealth_RGB.jpg";
import sickKidsLogo from "../../assets/logos/SickKids_Logo.png";
import temertyLogo from "../../assets/logos/UofT_Temerty_Faculty_of_Medicine_Logo.png";
import ebaseLogo from "../../assets/logos/eBASE_Logo.jpg";
import cochraneMethodsLogo from "../../assets/logos/Cochrane_Methods_Equity_Stacked_CMYK.jpg";

import fudanGradeCenterLogo from "../../assets/logos/fudan-grade-center-logo.png";
import unnc from "../../assets/logos/UNNC-logo-2021.jpg";
import beijing from "../../assets/logos/Beijing-GRADE-Center-logo.png";
import logoPeking from "../../assets/logos/Logo-Peking.jpg";
import guidance from "../../assets/logos/guidance.png";
import cebtm from "../../assets/logos/cebtm.png";
import ningbo from "../../assets/logos/ningbo.jpg";
import srs from "../../assets/logos/srs.jpg";

import { Team } from "../Team";
import {
  niphLink,
  mcMasterLink,
  evidenceprimeLink,
  ginLink,
  cochraneCanadaLink,
} from "../../lib/constants";

const partners = [
  {
    type: "image",
    logo: mcMasterLogo,
    title: "McMaster University",
    key: "mcmaster",
  },
  {
    type: "image",
    logo: niphLogo,
    title: "Norwegian Institute of Public Health",
    key: "niph",
  },
  {
    type: "image",
    logo: cochraneCanadaLogo,
    title: "Cochrane Canada",
    key: "cochrane-canada",
  },
  {
    type: "image",
    logo: MGCLogo,
    title: "Michael G. DeGroote Cochrane Canada Center",
    key: "mgc",
  },
  {
    type: "image",
    logo: cihrLogo,
    title: "Canadian Institutes of Health Research",
    key: "cihr",
  },
  {
    type: "image",
    logo: evidencePrimeLogo,
    title: "Evidence Prime",
    key: "evidence-prime",
    className: "evidence-prime",
  },
  {
    type: "image",
    logo: epistemonikosLogo,
    title: "Epistemonikos",
    key: "epistemonikos",
  },
  {
    type: "image",
    logo: loveLogo,
    title: "LOVE",
    key: "love",
  },
  {
    type: "image",
    logo: aubLogo,
    title: "American University of Beirut",
    key: "aub",
  },
  {
    type: "image",
    logo: biggLogo,
    title: "International database of GRADE guidelines",
    key: "bigg",
  },
  {
    type: "image",
    logo: gesiLogo,
    title: "The Global Evidence Synthesis Initiative",
    key: "gesi",
  },
  {
    type: "image",
    logo: cochraneSouthAfricaLogo,
    title: "Cochrane South Africa",
    key: "cochrane-south-africa",
  },
  {
    type: "image",
    logo: ginLogo,
    title: "Guidelines International Network",
    key: "gin",
  },
  {
    type: "image",
    logo: cochraneDeutschlandLogo,
    title: "Cochrane Deutschland",
    key: "cochrane-deutschland",
  },
  {
    type: "image",
    logo: amsterdamUMCLogo,
    title: "Amsterdam UMC",
    key: "amsterdam-umc",
  },
  {
    type: "image",
    logo: australiaJBILogo,
    title: "Australia JBI",
    key: "australia-jbi",
  },

  {
    type: "image",
    logo: archeLogo,
    title: "ARCHE",
    key: "arche",
  },
  {
    type: "image",
    logo: cochraneChildHealth,
    title: "Cochrane Child Health",
    key: "cochrane-child-health",
  },
  {
    type: "image",
    logo: sickKidsLogo,
    title: "Sick Kids",
    key: "sick-kids",
  },
  {
    type: "image",
    logo: temertyLogo,
    title: "Temerty Faculty",
    key: "temerty-faculty",
  },

  {
    type: "image",
    logo: barcelonaSantPauLogo,
    title: "Barcelona Sant Pau",
    key: "barcelona-sant-pau",
  },
  {
    type: "image",
    logo: germanyFreiburgLogo,
    title: "University of Freiburg",
    key: "germany-freiburg",
  },
  {
    type: "image",
    logo: iberoamericaCochrane,
    title: "Iberoamerica Cochrane",
    key: "iberoamerica-cochrane",
  },
  {
    type: "image",
    logo: pgimerLogo,
    title: "Post Graduate Institute of Medical Education & Research, Chandigarh",
    key: "pgimer",
  },
  {
    type: "image",
    logo: whoLogo,
    title: "WHO",
    key: "who",
  },
  {
    type: "image",
    logo: peterboroughPublicHealthLogo,
    title: "Perterborough Public Health",
    key: "lgh",
  },
  {
    type: "image",
    logo: niceLogo,
    title: "National Institute for Health and Care Excellence",
    key: "nice",
  },
  {
    type: "image",
    logo: czechCochraneLogo,
    title: "Cochrane Czech Republic",
    key: "cochrane-czech",
  },
  {
    type: "image",
    logo: gradeMasarykLogo,
    title: "Masaryk University GRADE Center",
    key: "masaryk-grade",
  },
  {
    type: "image",
    logo: jbiLogo,
    title: "A JBI Centre of Excellence",
    key: "jbi-centre",
  },
  {
    type: "image",
    logo: munimedLogo,
    title: "Institute of Biostatistics and Analyses",
    key: "munimed",
  },
  {
    type: "image",
    logo: apaaciLogo,
    title: "Asia Pacific Association of allergy, asthma and clinical immunology",
    key: "apaaci",
  },

  {
    type: "image",
    logo: kumcLogo,
    className: "kumc",
    title: "The University of Kansas",
    key: "kumc",
  },
  {
    type: "image",
    logo: agreeLogo,
    className: "agree",
    title: "Agree II",
    key: "agree",
  },
  {
    type: "image",
    logo: canadaFrancophoneLogo,
    title: "Canada Francophone",
    key: "canadaFrancophone",
  },
  {
    type: "image",
    logo: yealthLogo,
    title: "Yealth Logo",
    key: "yealthLogo",
  },
  {
    type: "image",
    logo: ACPLogo,
    title: "ACP logo",
    key: "acpLogo",
  },
  {
    type: "image",
    logo: hunimedLogo,
    title: "Humanitas University",
    key: "hunimedLogo",
  },
  {
    type: "image",
    logo: CADTHLogo,
    title: "CADTH Evidence Driven",
    key: "cadthLogo",
  },
  {
    type: "image",
    logo: lanzhouUniversityLogo,
    title: "Lanzhou University",
    key: "lanzhouUniversityLogo",
  },
  {
    type: "image",
    logo: sociedadePortuguesa,
    title: "Sociedade Portuguesa De Cuidados Intensivos",
    key: "sociedadePortuguesa",
  },
  {
    type: "image",
    logo: craScr,
    title: "Canadian Rheumatology Association",
    key: "cra-scr",
  },
  {
    type: "image",
    logo: ebaseLogo,
    title: "eBase",
    key: "ebase",
  },
  {
    type: "image",
    logo: cochraneMethodsLogo,
    title: "Cochrane Methods",
    key: "cochrane-methods",
  },
  {
    type: "image",
    logo: fudanGradeCenterLogo,
    title: "Fundan",
    key: "fundan",
  },
  {
    type: "image",
    logo: unnc,
    title: "UNNC",
    key: "unnc",
  },
  {
    type: "image",
    logo: beijing,
    title: "Beijing",
    key: "beijing",
  },
  {
    type: "image",
    logo: logoPeking,
    title: "Logo peking",
    key: "logo-peking",
  },
  {
    type: "image",
    logo: guidance,
    title: "Guidance",
    key: "guidance",
  },
  {
    type: "image",
    logo: cebtm,
    title: "cebtm",
    key: "cebtm",
  },
  {
    type: "image",
    logo: ningbo,
    title: "ningbo",
    key: "ningbo",
  },
  {
    type: "image",
    logo: srs,
    title: "srs",
    key: "srs",
  },
];

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="pb-6">
      <div className="w-full max-w-screen-lg relative mx-auto pb-6">
        <div className="w-full flex flex-row align-center justify-between mb-2">
          <h1 className={`pl-6 pt-2 text-2xl text-covid-100 font-normal`}>{t("labels.about")}</h1>
        </div>
        <Box className="p-5 bg-white">
          <div className="w-full p-4">
            <Trans i18nKey="app.about">
              The COVID19 living catalogue of guidelines collects best available recommendations on
              COVID19 for clinical, public health and health policy. It allows decision-makers to
              identify relevant recommendations for their question of interest. We use the broadly
              accepted population, interventions, comparators and outcomes (PICO) to map and
              catalogize questions and recommendations. One of its primary goals is to allow
              contextualized local, provincial, regional and jurisdictional decision-making. Our
              approach is to review all globally published recommendations on COVID19 and include
              all recommendations by key organizations including the World Health Organization
              (WHO), Public Health Agency of Canada (PHAC), Centers for Disease Control and
              Prevention (CDC), European CDC (ECDC), Arbeitsgemeinschaft der Wissenschaftlichen
              Medizinischen Fachgesellschaften e.V (AWMF or Association of the Scientific Medical
              Societies in Germany), National Institute for Health and Care Excellence (NICE),
              Canadian Task Force on Preventive Health Care (CTFPHC), COVID Network Meta-Analysis
              Initiative (COVID-NMA), National COVID-19 Clinical Evidence Task Force (Australia),
              National Institutes of Health (NIH), and the Scottish Intercollegiate Guidelines
              Network (SIGN). All recommendations are supported by a description of its PICO
              elements and links to interactive Summary of Findings (SoF) tables and the Evidence to
              Decision tables (EtDs) populated on GRADEPro and other information, if available.
              <br />
              <br />
              It is a product of the collaboration between{" "}
              <a
                className="text-covid-100 hover:opacity-50"
                href={cochraneCanadaLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Cochrane Canada
              </a>
              , the World Health Organization Collaborating Center for Infectious Diseases, Research
              Methods and Recommendations at{" "}
              <a
                className="text-covid-100 hover:opacity-50"
                href={mcMasterLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                McMaster University
              </a>
              ,{" "}
              <a
                className="text-covid-100 hover:opacity-50"
                href={evidenceprimeLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Evidence Prime
              </a>
              , the{" "}
              <a
                className="text-covid-100 hover:opacity-50"
                href={niphLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Norwegian Institute of Public Health
              </a>{" "}
              , the{" "}
              <a
                className="text-covid-100 hover:opacity-50"
                href={ginLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Guidelines International Network
              </a>{" "}
              and many other institutions or organizations (see below).
              <br />
              <br />
              This platform is free to access and will feed on continued input from guideline
              developers which we encourage and users and, thus, it’s easy-to-use functions will
              continue to improve and it will be updated regularly to maintain relevance.
              <br />
              There is context specific information when users access specific sections on the map.
              Also, here is a link to additional information that explain what we are doing and
              planning:{" "}
              <a
                download
                className="text-covid-100 hover:opacity-50"
                href={process.env.PUBLIC_URL + "/resources/COVID-19-summary-slides.pptx"}
              >
                COVID-19 summary slides.pptx
              </a>
              <br />
            </Trans>
          </div>
        </Box>
      </div>
      <div className="w-full max-w-screen-lg relative mx-auto mb-6">
        <div className="w-full flex flex-row align-center justify-between mb-2">
          <h1 className={`pl-6 pt-2 text-2xl text-covid-100 font-normal`}>
            {t("labels.disclaimer")}
          </h1>
        </div>
        <Box className="p-5 bg-white">
          <div className="w-full p-4 flex flex-row flex-wrap about-page--partners justify-center align-center">
            <Trans i18nKey="app.disclaimer">
              <p>
                Please read this disclaimer ("disclaimer") carefully before using the COVID19
                Recommendations and Gateway to Contextualization (
                <Link to="/" className="text-covid-100 hover:opacity-50">
                  https://covid19.recmap.org/
                </Link>
                ) website ("website").
              </p>
              <br />
              <p>
                We do not take responsibility for the accuracy or completeness of the content
                contained within this website. Although reasonable efforts and care have been
                applied in its preparation, we assume no liability or responsibility for errors or
                omissions and consulting the original source of information may be required.
                Recommendations may be updated or changed as the research evidence and guideline
                literature accumulate. The information provided within this website is not intended
                as a substitute for professional advice.
              </p>
            </Trans>
          </div>
        </Box>
      </div>
      <div className="w-full max-w-screen-lg relative mx-auto mb-6">
        <div className="w-full flex flex-row align-center justify-between mb-2">
          <h1 className={`pl-6 pt-2 text-2xl text-covid-100 font-normal`}>
            {t("labels.partners")}
          </h1>
        </div>
        <Box className="p-5 bg-white">
          <div className="w-full p-4 flex flex-row flex-wrap about-page--partners justify-center align-center">
            {map(partners, ({ type, logo, title, key }) =>
              type === "image" ? (
                <img
                  src={logo}
                  alt={title}
                  title={title}
                  key={key}
                  className={`h-15 mb-6 ${key ? key : ""}`}
                />
              ) : (
                <span key={key} className={`h-15 mb-6 ${key ? key : ""}`}>
                  {title}
                </span>
              )
            )}
          </div>
        </Box>
      </div>
      <Team />
    </div>
  );
};

export default About;
