import axios from "axios";

const serverUrl =
  // eslint-disable-next-line no-undef
  process.env.NODE_ENV !== "production"
    ? "https://covid19.test.evidenceprime.com/api"
    : `${window.location.origin}/api`; // TODO change this after setting up production

export const axiosInstance = axios.create({
  baseURL: serverUrl,
  withCredentials: true
});
