import _ from "lodash";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import noIcon from "../../assets/no.svg";
import probablyNoIcon from "../../assets/probably_no.svg";
import probablyYesIcon from "../../assets/probably_yes.svg";
import yesIcon from "../../assets/yes.svg";
import CustomTable from "../common/CustomTable";
import ExpandableCard from "../common/ExpandableCard";

const ICONS = {
  yes: yesIcon,
  probably_yes: probablyYesIcon,
  probably_no: probablyNoIcon,
  no: noIcon,
};

const POSSIBLE_OPTIONS = ["yes", "probably_yes", "probably_no", "no"];

export const SECTIONS_TO_BE_SEPARATED = ["implementationConsiderationsOrRemarks"];

type TSection = {
  selectedOption: "yes" | "probably_yes" | "probably_no" | "no";
  content: string;
};

interface IConclusionsRow {
  section: TSection;
  sectionName: string;
}

export const ConclusionsRow = ({ section, sectionName }: IConclusionsRow) => {
  const { t } = useTranslation();

  return (
    <div className="conclusions-row">
      {!_.includes(SECTIONS_TO_BE_SEPARATED, sectionName) &&
        !_.isEmpty(section.selectedOption) &&
        _.includes(POSSIBLE_OPTIONS, section.selectedOption) && (
          <div className="conclusions-row__marker flex flex-row">
            <img src={ICONS[section.selectedOption]} alt={section.selectedOption} />
            <span className="font-semibold">{t(`labels.${section.selectedOption}`)}.</span>
          </div>
        )}
      {!_.isEmpty(section.content) && (
        <div
          className="conclusions-row__content"
          dangerouslySetInnerHTML={{ __html: section.content }}
        />
      )}
    </div>
  );
};

interface IConclusions {
  gps: boolean;
  sections: {
    [key: string]: TSection;
  };
  sectionsOrder: string[];
  title: string;
  reportedAsGPS?: boolean;
  toggleRef: HTMLDivElement;
}

const Conclusions = ({
  gps,
  sections,
  sectionsOrder,
  title,
  reportedAsGPS,
  toggleRef,
}: IConclusions) => {
  const { t } = useTranslation();

  const rows = useMemo(
    () =>
      _.reduce(
        sectionsOrder,
        (acc, sectionName) => {
          const section = _.includes(SECTIONS_TO_BE_SEPARATED, sectionName)
            ? _.omit(sections[sectionName], "selectedOption")
            : sections[sectionName];
          return !_.isEmpty(section)
            ? acc.concat({
                label: t(`sections.${sectionName}`),
                value: <ConclusionsRow section={section as TSection} sectionName={sectionName} />,
              })
            : acc;
        },
        [] as any[]
      ),
    [t, sections, sectionsOrder]
  );

  const hasAnyValue = useMemo(() => _.some(sections, (section) => !_.isEmpty(section)), [sections]);
  if (!hasAnyValue) return null;
  return (
    <>
      <ExpandableCard
        toggleRef={toggleRef}
        title={
          !_.isEmpty(title)
            ? title
            : gps
            ? t("labels.goodPracticeStatementCriteria")
            : t("labels.additionalInformation")
        }
        tooltip={t("tooltips.additionalInformation")}
      >
        {gps && reportedAsGPS !== null && <ReportedAsGPS reportedAsGPS={reportedAsGPS || false} />}
        <CustomTable className="flex-grow" rows={rows} />
      </ExpandableCard>
    </>
  );
};

Conclusions.propTypes = {
  gps: PropTypes.bool,
  reportedAsGPS: PropTypes.bool,
  title: PropTypes.string,
  sections: PropTypes.objectOf(
    PropTypes.shape({
      content: PropTypes.string,
      selectedOption: PropTypes.oneOf(POSSIBLE_OPTIONS),
    })
  ).isRequired,
  sectionsOrder: PropTypes.arrayOf(PropTypes.string),
};

Conclusions.defaultProps = {
  sections: {},
  sectionsOrder: [],
  gps: false,
  reportedAsGPS: null,
  title: null,
};

const ReportedAsGPS = ({ reportedAsGPS }: { reportedAsGPS: boolean }) => {
  const { t } = useTranslation();
  const textKey = reportedAsGPS ? "reportedAsGPS" : "judgedToBeABestPractice";
  return <div className="mb-5">{t(`detailsOfRecommendationPage.${textKey}`)}</div>;
};

ReportedAsGPS.propTypes = {
  reportedAsGPS: PropTypes.bool.isRequired,
};

export default Conclusions;
