import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AdaptedTooltip from "../common/AdaptedTooltip";
import TypeHeader from "../common/TypeHeader";
import RecommendationCertainty from "../recommendation/RecommendationCertainty";
import RecommendationSnippet from "../recommendation/RecommendationSnippet";
import RecommendationStrength from "../recommendation/RecommendationStrength";

const RecommendationsComparison = ({
  recommendationsToCompare,
  highlightedRecommendation,
  highlightRecommendation,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ul className="recommendations-to-compare">
        {_.map(recommendationsToCompare, (rec) => (
          <li
            key={rec["@id"]}
            className={`flex flex-row pb-4 pt-2 px-2 mb-4 
          ${highlightedRecommendation === rec["@id"] ? "highlighted" : ""}`}
          >
            <div className="flex-grow mr-4">
              <TypeHeader
                className="mb-2"
                adapted={rec.adapted || rec.adolopment}
                recommendationFormality={rec.recommendationFormality}
                size="small"
                type="recommendation"
              />
              <Link
                to={`/recommendation/${rec["@id"]}`}
                className="hover:bg-blue-1100"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="hover:bg-blue-1100">
                  <RecommendationSnippet text={rec.recommendation} />
                </div>
              </Link>
              <div className="flex text-2xs">
                {rec.gradeCertaintyOfEvidence && rec.gradeCertaintyOfEvidenceLabel && (
                  <div className="flex-grow mt-1">
                    <RecommendationCertainty
                      noBox
                      transformed={rec.transformed}
                      gradeCertaintyOfEvidence={rec.gradeCertaintyOfEvidence}
                      gradeCertaintyOfEvidenceLabel={rec.gradeCertaintyOfEvidenceLabel}
                    />
                  </div>
                )}
                <div className="mt-1">
                  {rec.gradeStrength && rec.recommendationDirection && (
                    <>
                      <div className="mt-1 font-semibold">
                        {t("recommendation.recommendation_strength")}
                      </div>
                      <RecommendationStrength
                        className="mb-2"
                        noBox
                        gradeStrength={rec.gradeStrength}
                        recommendationDirection={rec.recommendationDirection}
                      />
                    </>
                  )}
                </div>
              </div>
              {!_.isEmpty(rec.guidelineSource) && (
                <div className="text-xs">
                  <div className="font-semibold mt-3">{t("labels.source_of_recommendation")}</div>
                  <div>{rec.guidelineSource}</div>
                </div>
              )}
            </div>
            <div className="highlight-container">
              <button
                className={`btn ${highlightedRecommendation === rec["@id"] ? "highlight" : ""}`}
                onClick={() =>
                  highlightRecommendation(
                    highlightedRecommendation === rec["@id"] ? "" : rec["@id"]
                  )
                }
              >
                {highlightedRecommendation === rec["@id"]
                  ? t("labels.highlighted")
                  : t("actions.highlight")}
              </button>
            </div>
          </li>
        ))}
      </ul>
      <AdaptedTooltip />
    </>
  );
};

RecommendationsComparison.propTypes = {
  recommendationsToCompare: PropTypes.arrayOf(
    PropTypes.shape({
      "@id": PropTypes.string.isRequired,
      recommendation: PropTypes.string.isRequired,
      recommendationFormality: PropTypes.string.isRequired,
      guidelineCitation: PropTypes.string,
    }).isRequired
  ).isRequired,
  highlightedRecommendation: PropTypes.string.isRequired,
  highlightRecommendation: PropTypes.func.isRequired,
};

export default RecommendationsComparison;
