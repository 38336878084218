import _ from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getTranslatedFilterValue } from "../../lib/helpers";

interface IFilterTags {
  currentFilters: Record<string, string>;
  onRemove: (filterKey: string) => void;
}

const useTranslatedFilters = (value: string, translationFn: (value: string) => string) => {
  const [short, full] = useMemo(() => {
    const translated = translationFn(value);
    return [translated.length > 16 ? `${translated.slice(0, 16)}...` : translated, translated];
  }, [value, translationFn]);

  return [short, full];
};

const FilterTags = ({ currentFilters, onRemove }: IFilterTags) => {
  const filters = useMemo(() => _.omit(currentFilters, ["lng", "searchQuery"]), [currentFilters]);

  return (
    !_.isEmpty(filters) && (
      <div id="tags-container" className="tags-list flex items-center">
        {_.map(filters, (value, key) => {
          return <Tag key={key} value={value} filterKey={key} onRemove={onRemove} />;
        })}
      </div>
    )
  );
};

interface ITag {
  value: string;
  filterKey: string;
  onRemove: (filterKey: string) => void;
}
const Tag = ({ value, filterKey, onRemove }: ITag) => {
  const { t } = useTranslation();

  const [shortLabel, fullLabel] = useTranslatedFilters(`filters.labels.${filterKey}`, t);
  const [shortValue, fullValue] = useTranslatedFilters(
    value,
    getTranslatedFilterValue(t, filterKey)
  );

  return (
    <div className="tags-list__tag" data-tip={`${fullLabel}: ${fullValue}`}>
      {`${shortLabel}: ${shortValue}`}
      <button className="remove-filter" onClick={() => onRemove(filterKey)}>
        &#215;
      </button>
    </div>
  );
};

export default FilterTags;
