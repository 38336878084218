import React, { ReactNode } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isValidLink } from "../../lib/utils";

export interface IRow {
  label: string;
  value: ReactNode;
  tooltip?: string;
}

const RowValue = ({ label, value }: IRow) => {
  const { t } = useTranslation();
  if (label.toLowerCase() === "url") {
    return (
      <a className="external-link" href={value as string} target="_blank" rel="noreferrer noopener">
        {t("actions.go_to_website")}
      </a>
    );
  } else if (React.isValidElement(value)) {
    return React.cloneElement(value);
  } else if (isValidLink(value)) {
    return (
      <a className="external-link" href={value as string} target="_blank" rel="noreferrer noopener">
        {value}
      </a>
    );
  } else {
    return <span dangerouslySetInnerHTML={{ __html: value as string }} />;
  }
};

interface ICustomTable {
  className: string;
  labelClassName: string;
  rowClassName: string;
  rows: IRow[];
  valueClassName: string;
}

const CustomTable = ({
  className,
  labelClassName,
  rowClassName,
  rows,
  valueClassName,
}: ICustomTable) => {
  return (
    <div className={className}>
      {_.compact(rows).map((row) => (
        <div
          key={_.kebabCase(row.label)}
          className={`custom-table flex flex-col sm:flex-row ${rowClassName}`}
        >
          <div
            className={`custom-table__label py-1 px-2 sm:py-3 sm:px-3 text-covid-100 font-semibold
            ${labelClassName}`}
          >
            {row.label}
            {row.tooltip && <span className="info-tooltip-icon" data-tip={row.tooltip} />}
          </div>
          <div
            className={`custom-table__value  
            ${valueClassName}`}
          >
            <RowValue label={row.label} value={row.value} />
          </div>
        </div>
      ))}
    </div>
  );
};

CustomTable.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.node.isRequired,
    })
  ),
  valueClassName: PropTypes.string,
};

CustomTable.defaultProps = {
  className: "",
  labelClassName: "",
  rowClassName: "",
  valueClassName: "py-1 px-2 mb-2 sm:mb-0 sm:py-3 sm:px-3",
};

export default CustomTable;
