import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetApi } from "../../lib/api";
import LargeSpinner from "../common/LargeSpinner";
import Recommendation from "../recommendation/Recommendation";

const RecommendationPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [{ data, isLoading, isError }] = useGetApi(`/recommendations/${id}`);

  return (
    <div className="recommendation font-transat">
      {isError && <div>{t("errors.fetching_failed")}</div>}
      {isLoading ? <LargeSpinner /> : data.document && <Recommendation document={data.document} />}
    </div>
  );
};

export default RecommendationPage;
