import _ from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TCertaintyValue, CERTAINTY_TYPES } from "../../lib/types";
import CertaintyCircles from "./CertaintyCircles";

interface ICertainty {
  signSize: "small" | "regular";
  value: TCertaintyValue;
}

const Certainty = ({ signSize, value }: ICertainty) => {
  const { t } = useTranslation();

  const signClassNames = `${signSize === "small" ? "quality-sign--small" : ""}`;
  const text = useMemo(
    () => (_.includes(CERTAINTY_TYPES, value) ? t(`recommendation.certainty.${value}`) : value),
    [value, t]
  );
  return (
    <div className="certainty flex flex-row items-center">
      <div className="whitespace-no-wrap">
        <CertaintyCircles value={value} signClassName={signClassNames} />
      </div>
      <div className="ml-2 whitespace-no-wrap" style={{ lineHeight: "15px" }}>
        {text}
      </div>
    </div>
  );
};

export default Certainty;
