import _ from "lodash";
import { DateTime } from "luxon";
import React, { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { useGetApi } from "../../lib/api";
import { parseTable } from "../../lib/helpers";
import LargeSpinner from "../common/LargeSpinner";

export default function OurPageInNumbers(): ReactElement {
  const { t } = useTranslation();
  // @ts-ignore
  const [{ data = [], isLoading }] = useGetApi(`/statistics`);

  const response = (_.first(data) as { body: string }) || { body: "" };

  return isLoading && _.isEmpty(data) ? (
    <LargeSpinner />
  ) : (
    <div className="our-page-in-numbers py-12">
      <h1 className="text-3xl text-white text-center mb-6">
        {t("statistics.ourProjectInNumbers")}
      </h1>
      <Charts table={response.body} />
    </div>
  );
}

interface ICharts {
  table: string;
}

type TDataRow = {
  date: string;
  [key: string]: number | string;
};

const Charts = ({ table }: ICharts): ReactElement => {
  const parsedTable = parseTable(table);
  const keys = useMemo(() => _.without(_.keys(_.first(parsedTable)), "date"), [parsedTable]);

  const weeksCount = useMemo(() => {
    const dates = _.map(parsedTable, "date");
    const startDate = DateTime.fromJSDate(new Date(_.first(dates)!));
    const endDate = DateTime.fromJSDate(new Date(_.last(dates)!));

    return Math.floor(endDate.diff(startDate, "weeks").toObject().weeks || 0);
  }, [parsedTable]);

  return (
    <div className="mx-auto max-w-2xl">
      {_.map(keys, (key) => (
        <Chart weeksCount={weeksCount} key={key} data={parsedTable as TDataRow[]} dataKey={key} />
      ))}
    </div>
  );
};

const TICKS_CONFIG = {
  stroke: "#000",
  strokeWidth: 0.5,
  fontSize: 10,
  wordWrap: "break-word",
};

const Chart = ({
  weeksCount,
  dataKey,
  data,
}: {
  weeksCount: number;
  dataKey: string;
  data: TDataRow[];
}) => {
  const { t } = useTranslation();
  const first = useMemo((): number => Number(_.first(_.map(data, dataKey))), [data, dataKey]);
  const totalCount = useMemo((): number => _.last(_.map(data, dataKey)) as number, [data, dataKey]);
  const newCount = useMemo((): number => {
    const lastButOne = Number(_.nth(_.map(data, dataKey), -2));
    return totalCount - lastButOne;
  }, [data, dataKey, totalCount]);

  const convertedToNumbers = _.map(data, (row) =>
    _.mapValues(row, (value, key) => (key === "date" ? value : Number(value)))
  );
  const previousUploadDate = useMemo((): string => String(_.nth(_.map(data, "date"), -2)), [data]);

  return (
    <div className="mb-4">
      <div className="flex flex-row">
        <div className="flex-grow">
          <h2 className="mb-2">{t(`statistics.${dataKey}`)}</h2>
          <ResponsiveContainer height={120} width="100%">
            <LineChart data={convertedToNumbers}>
              <Line dot={false} dataKey={dataKey} type="monotone" stroke="#ffffff" />
              <XAxis
                tick={TICKS_CONFIG}
                stroke="#fff"
                ticks={[
                  0,
                  Math.floor(weeksCount * 0.33),
                  Math.floor(weeksCount * 0.66),
                  weeksCount,
                ]}
              />
              <YAxis
                allowDataOverflow
                type="number"
                stroke="#fff"
                tick={TICKS_CONFIG}
                domain={[first, "auto"]}
              />
            </LineChart>
          </ResponsiveContainer>
          <h3 className="text-right -mt-4">{t("statistics.weeks")}</h3>
        </div>
        <div>
          <Bubble
            total={totalCount}
            newCount={newCount}
            date={previousUploadDate}
            dataKey={dataKey}
          />
        </div>
      </div>
    </div>
  );
};

interface IBubble {
  total: number;
  newCount: number;
  date: string;
  dataKey: string;
}

const Bubble = ({ total, newCount, date, dataKey }: IBubble) => {
  const { t } = useTranslation();
  const bubbleKey = useMemo(() => {
    switch (dataKey) {
      case "retrievedCitationsForScreening":
        return "citations";
      case "guidelinesExtractedOnPlatform":
        return "guidelines";
      case "recommendationsExtractedOnPlatform":
        return "recommendations";
    }
  }, [dataKey]);

  return (
    <div className="bubble-container">
      <div className="bubble"></div>
      <h1>
        {total}
        <span>{t(`statistics.${bubbleKey}`)}</span>
      </h1>
      <div dangerouslySetInnerHTML={{ __html: t("statistics.newEntities", { newCount, date }) }} />
    </div>
  );
};
