import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { isValidLink } from "../../lib/utils";
import Box from "../common/Box";
import ExternalLink from "../common/ExternalLink";

type TRow = {
  authors: string;
  title: string;
  linkOrDoi: string;
};

interface ITableRow {
  row: TRow;
}

const PUBLICATIONS = [
  {
    authors:
      "Tamara Lotfi, Adrienne Stevens, Elie A. Akl, Maicon Falavigna, Tamara Kredo, Joseph L. Mathew, Holger J. Schünemann.",
    title:
      "Getting trustworthy guidelines into the hands of decision-makers and supporting their consideration of contextual factors for implementation globally: recommendation mapping of COVID-19 guidelines. Journal of Clinical Epidemiology. Volume 135, 2021, Pages 182-186, ISSN 0895-4356.",
    linkOrDoi: "https://doi.org/10.1016/j.jclinepi.2021.03.034",
  },
  {
    authors: "Stevens A, Lotfi T, Akl EA, Falavigna M, Kredo T, Mathew JL, et al.",
    title:
      "The eCOVID-19 living recommendations map and gateway to contextualisation. In: Collaborating in response to COVID-19: editorial and methods initiatives across Cochrane. Cochrane Database of Systematic Reviews 2020;(12 Suppl 1):54-7.",
    linkOrDoi: "https://doi.org/10.1002/14651858.CD202002",
  },
  {
    authors: "Zachary Munn, Sara Twaddle, Duncan Service, et al.",
    title:
      "Developing Guidelines Before, During, and After the COVID-19 Pandemic. Ann Intern Med.2020;173:1012-1014. [Epub ahead of print 15 September 2020].",
    linkOrDoi: "doi:10.7326/M20-4907",
  },
  {
    authors: "Joseph L Mathew.",
    title:
      "Low-value health care in the COVID-19 pandemic. The Lancet Global Health, Volume 9, Issue 9, e1214.",
    linkOrDoi: "https://doi.org/10.1016/S2214-109X(21)00354-5",
  },
  {
    authors: "Lotfi, T., Hajizadeh, A., Moja, L., Akl, E. A., Piggott, T., et al.",
    title:
      "(2021). A taxonomy and framework for identifying and developing actionable statements in guidelines suggests avoiding informal recommendations. Journal of clinical epidemiology, S0895-4356(21)00314-0. Advance online publication.",
    linkOrDoi: "https://doi.org/10.1016/j.jclinepi.2021.09.028",
  },
  {
    authors:
      "Klugar, M., Kantorová, L., Pokorná, A., Líčeník, R., Dušek, L., Schünemann, H.J., Riad, A., Kantor, J. and Klugarová, J.",
    title:
      "Visual transformation for guidelines presentation of the strength of recommendations and the certainty of evidence. Journal of clinical epidemiology. 2021",
    linkOrDoi: "https://doi.org/10.1016/j.jclinepi.2021.11.009",
  },
  {
    authors: "Dewidar O, Lotfi T, Langendam MW, Parmelli E, et al.",
    title:
      "Good or best practice statements: proposal for the operationalisation and implementation of GRADE guidance",
    linkOrDoi: "http://dx.doi.org/10.1136/bmjebm-2022-111962",
  },
  {
    authors: "Dewidar O, Lotfi T, Langendam M, Parmelli E, et al.",
    title:
      "Which actionable statements qualify as good practice statements In Covid-19 guidelines? A systematic appraisal",
    linkOrDoi: "http://dx.doi.org/10.1136/bmjebm-2021-111866",
  },
  {
    authors: "Nasir ZH, Mertz D, Nieuwlaat R, Santesso N, et al.",
    title:
      "An evaluation of the eCOVID19 Recommendation Map identified diverging Clinical and Public Health guidance",
    linkOrDoi: "https://doi.org/10.1016/j.jclinepi.2022.03.008",
  },
  {
    authors: "Pottie, Kevin, Maureen Smith, Micayla Matthews, Nancy Santesso, et al.",
    title:
      "A multistakeholder development process to prioritize and translate COVID-19 health recommendations for patients, caregivers and the public. A case study of the COVID-19 Recommendation Map.",
    linkOrDoi: "https://doi.org/10.1016/j.jclinepi.2022.04.012",
  },
  {
    authors:
      "Stallwood, L., Sammy, A., Prebeg, M., Relihan, J., Baba, A., Charide, R., et al. (2023).",
    title:
      " Plain Language vs Standard Format for Youth Understanding of COVID-19 Recommendations: A Randomized Clinical Trial. JAMA pediatrics, 177(9), 956-965.",
    linkOrDoi: "https://jamanetwork.com/journals/jamapediatrics/fullarticle/2808109",
  },
  {
    authors:
      "Shahab Sayfi, Rana Charide, Sarah A. Elliott, Lisa Hartling, Matthew Munan, Lisa Stallwood, et al.",
    title:
      "A multimethods randomized trial found that plain language versions improved adults understanding of health recommendations. Published:November 24, 2023.",
    linkOrDoi: "https://doi.org/10.1016/j.jclinepi.2023.11.009",
  },
  {
    authors:
      "Elliott, S. A., Scott, S. D., Charide, R., Patterson-Stallwood, L., Sayfi, S., Motilall, A. et al. (2023).",
    title:
      "A multimethods randomized trial found that plain language versions improved parents’ understanding of health recommendations. Journal of Clinical Epidemiology, 161, 8-19.",
    linkOrDoi: "https://doi.org/10.1016/j.jclinepi.2023.06.018",
  },
  {
    authors: "Charide, R., Stallwood, L., Munan, M. et al.",
    title:
      "Knowledge mobilization activities to support decision-making by youth, parents, and adults using a systematic and living map of evidence and recommendations on COVID-19: protocol for three randomized controlled trials and qualitative user-experience studies. Trials 24, 27 (2023).",
    linkOrDoi: "https://doi.org/10.1186/s13063-023-07067-9",
  },
  {
    authors:
      "Omar Dewidar, Mostafa Bondok, Leenah Abdelrazeq, Khadija Aliyeva, Karla Solo, Vivian Welch, Romina Brignardello-Petersen, et al.",
    title:
      "Equity issues rarely addressed in the development of COVID-19 formal recommendations and good practice statements: a cross-sectional study. Journal of Clinical Epidemiology. Volume 161. 2023. Pages 116-126. ISSN 0895-4356",
    linkOrDoi: "https://doi.org/10.1016/j.jclinepi.2023.08.002",
  },
];

const TableRow = ({ row }: ITableRow) => {
  return (
    <div className="mb-5 text-xs">
      <div className="break-words">{row.authors}</div>
      <h1 className="font-transat font-semibold text-base mb-2 break-words">{row.title}</h1>
      <div className="break-all font-transat font-semibold text-base mb-2">
        {isValidLink(row.linkOrDoi) ? (
          <ExternalLink url={row.linkOrDoi} className="block my-2 text-covid-100">
            {row.linkOrDoi}
          </ExternalLink>
        ) : (
          row.linkOrDoi
        )}
      </div>
      <div className="gradient-separator" />
    </div>
  );
};

const OurPublications = () => {
  const { t } = useTranslation();

  return (
    <div className="pb-6">
      <div className="w-full max-w-screen-lg relative mx-auto pb-6">
        <div className="w-full flex flex-row align-center justify-between mb-2">
          <h1 className={`pl-6 pt-2 text-2xl text-covid-100 font-normal`}>
            {t("labels.ourPublications")}
          </h1>
        </div>
        <Box className="p-5 bg-white mt-5">
          <div className="p-4">
            <OurPublicationsTable />
          </div>
        </Box>
      </div>
    </div>
  );
};

const OurPublicationsTable = () => {
  return (
    <div>
      {_.map(PUBLICATIONS, (publication, idx) => (
        <TableRow key={idx} row={publication} />
      ))}
    </div>
  );
};

export default OurPublications;
