import PropTypes from "prop-types";
import React, { ReactNode } from "react";

interface IBox {
  className?: string;
  contentClassName?: string;
  label?: string;
  children: ReactNode;
  tooltip?: ReactNode;
}

const Box = ({ className, contentClassName, label, children, tooltip }: IBox) => {
  return (
    <div data-tip={tooltip} className={`rounded-lg ${className}`}>
      {label && <div className="text-blue-800 text-sm">{label}</div>}
      <div className={contentClassName}>{children}</div>
    </div>
  );
};

Box.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.node,
};

Box.defaultProps = {
  className: "",
  contentClassName: "",
  tooltip: undefined,
  label: undefined,
};

export default Box;
