import React from "react";
import _ from 'lodash';
import RecommendationItem from "./RecommendationItem";
import { TRecommendationListItem } from "../../lib/types";

interface IRecommendationsList {
  recommendations: TRecommendationListItem[],
  displaySource?: boolean;
}

const RecommendationsList = ({ recommendations, displaySource = true }: IRecommendationsList) => (
  <ul className="recommendation-list ml-1 md:ml-1 mt-6 mr-1 md:mr-2">
    <li>
      <div className="bg-transparent pb-1">
        <div className="mb-1">
          {_.map(recommendations, (recommendation, idx) => (
            <RecommendationItem
              key={`${idx}-${recommendation["@id"]}`}
              recommendation={recommendation}
              displaySource={displaySource}
            />
          ))}
        </div>
      </div>
    </li>
  </ul>
);

export default RecommendationsList
