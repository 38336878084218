import React from "react";
import { useTranslation } from "react-i18next";

const Newsletter = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="text-center font-semibold mb-6 text-xl">{t("messages.newsletter")}</div>
      <iframe
        title="mailin-list"
        id="iframewin"
        width="100%"
        height="250px"
        src="https://zcsub-cmpzourl.maillist-manage.com/ua/Optin?od=11287ecc23927c&zx=12f8964ab&lD=1e63bcddcab5aca3&n=11699f74e07af8b&sD=1e63bcddcab5b3e6"
      ></iframe>
    </div>
  );
};

export default Newsletter;
