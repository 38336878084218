import React from "react";
import { useTranslation } from "react-i18next";

interface ITransformedProps {
  transformed: boolean;
}

const Transformed = ({ transformed }: ITransformedProps) => {
  const { t } = useTranslation();
  return transformed ? (
    <div className="label-transformed" data-html={true} data-tip={t("tooltips.transformed")}>
      {t("labels.transformed")}
    </div>
  ) : null;
};

export default Transformed;
