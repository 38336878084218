import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import ReactTooltip from "react-tooltip";
import Modal from "../common/Modal";
import RecommendationsComparison from "../grid/RecommendationsComparison";
import RMT from "./RMT";

const RecommendationsMapTable = ({
  grid,
  displayMode,
  colorMap,
  isLoading,
  currentIntentSelected,
  onChangeIntentSelected,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [recommendationsToCompare, setRecommendationsToCompare] = useState([]);
  const [highlightedRecommendation, setHighlightedRecommendation] = useState("");

  const closeModal = useCallback(() => setModalOpen(false), []);
  const onClick = (e) => {
    if (e.target.classList.contains("rmt-pill")) {
      const content = JSON.parse(e.target.getAttribute("data-column"));
      if (content.count < 1) return;
      setModalOpen(true);
      const recs = _.map(content.recommendations, (recId) => grid.documents[recId]);
      setRecommendationsToCompare(recs);
    } else {
      return;
    }
  };

  return (
    <section id="rmt-table">
      <div className="rmt">
        <div className="flex-row">
          <RMT
            currentIntentSelected={currentIntentSelected}
            onChangeIntentSelected={onChangeIntentSelected}
            onClick={onClick}
            grid={grid}
            displayMode={displayMode}
            isLoading={isLoading}
            colorMap={colorMap}
            highlightedRecommendation={highlightedRecommendation}
          />
          <Modal isOpen={modalOpen} onClose={closeModal} closeButton={true}>
            <RecommendationsComparison
              highlightRecommendation={setHighlightedRecommendation}
              highlightedRecommendation={highlightedRecommendation}
              recommendationsToCompare={recommendationsToCompare}
            />
          </Modal>
          <ReactTooltip
            className="tooltip-container"
            type="dark"
            effect="float"
            backgroundColor="#031B49"
          />
        </div>
      </div>
    </section>
  );
};

RecommendationsMapTable.propTypes = {
  currentIntentSelected: PropTypes.string.isRequired,
  onChangeIntentSelected: PropTypes.func.isRequired,
  displayMode: PropTypes.oneOf(["normal", "heatMap"]),
  colorMap: PropTypes.arrayOf(PropTypes.string).isRequired,
};

RecommendationsMapTable.defaultProps = {
  displayMode: "normal",
};

export default RecommendationsMapTable;
