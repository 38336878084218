import React from "react";
import _ from "lodash";

interface IEmlLinks {
  links: { url: string; label: string }[];
}

const EmlLinks = ({ links }: IEmlLinks) => (
  <div className="gradient-links-bg py-1 px-2 sm:py-3 sm:px-3 h-full flex flex-row">
    <div className="flex-grow">
      {_.map(links, (link) => (
        <div key={link.url} className="pb-1 last:pb-0">
          <a href={link.url} target="_blank" rel="noopener noreferrer">
            {link.label}
          </a>
        </div>
      ))}
    </div>
    <div>
      <span className="external-logo-link eml-link" />
    </div>
  </div>
);

export default EmlLinks;
