import { Modal as ModalBase } from "@evidenceprime/react-components";
import PropTypes from "prop-types";
import React, { ReactNode, useRef } from "react";
import { useHandleClickOutside } from "../../lib/custom_hooks";

interface IModal {
  isOpen: boolean;
  children: ReactNode;
  onClose: () => void;
  closeButton?: boolean;
  modalSize?: string;
  closeOnClickOutside?: boolean;
}

const Modal = ({
  isOpen,
  children,
  onClose,
  closeButton,
  modalSize,
  closeOnClickOutside,
}: IModal) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useHandleClickOutside(contentRef, onClose, closeOnClickOutside);

  return (
    <ModalBase isOpen={isOpen} modalSize={modalSize}>
      <div className="modal-content" ref={contentRef}>
        {closeButton && onClose && (
          <div>
            <span className="close-modal" onClick={onClose} />
          </div>
        )}
        {children}
      </div>
    </ModalBase>
  );
};

Modal.propTypes = {
  closeButton: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  modalSize: PropTypes.string,
  closeOnClickOutside: PropTypes.bool,
};

Modal.defaultProps = {
  closeButton: false,
  modalSize: "medium",
  closeOnClickOutside: true,
};

export default Modal;
