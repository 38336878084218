import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import ExpandableCard from "../common/ExpandableCard";

const Coi = ({ coi, toggleRef }) => {
  const { t } = useTranslation();

  return !_.isEmpty(coi) ? (
    <ExpandableCard
      title={t("labels.coi")}
      tooltip={t("tooltips.conflictOfInterests")}
      toggleRef={toggleRef}
    >
      <div dangerouslySetInnerHTML={{ __html: coi }} />
    </ExpandableCard>
  ) : null;
};

Coi.propTypes = {
  coi: PropTypes.string,
};

Coi.defaultProps = {
  coi: undefined,
};

export default Coi;
