import PropTypes from "prop-types";
import React, { ReactNode, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { Link, useHistory } from "react-router-dom";
import contextualizationIcon from "../../assets/contextualization.svg";
import careIcon from "../../assets/covid-care.svg";
import covidLogo from "../../assets/COVID_gradient.svg";
import gridIcon from "../../assets/grid-icon.svg";
import listIcon from "../../assets/list-icon.svg";
import plrIcon from "../../assets/list-plr.svg";
import cihrLogo from "../../assets/logos/cihr-logo.svg";
import videoIcon from "../../assets/video.svg";
import { useSearchQuery } from "../../lib/custom_hooks";
import AdolopmentDescription from "../common/AdolopmentDescription";
import AdolopmentInstructions from "../common/AdolopmentInstructions";
import GuideSection from "../common/GuideSection";
import Search from "../common/Search";
import WhatWeAreCurrentlyWorkingOn from "../common/WhatWeAreCurrentlyWorkingOn";
import Footer from "../layouts/Footer";
import OurPageInNumbers from "../statistics/OurPageInNumbers";
import TwitterNews from "../twitter/TwitterNews";
import Newsletter from "../common/Newsletter";
import SearchInstructions from "../common/SearchInstructions";
import InstructionModal from "../common/InstructionModal";

interface ILinkToSubpage {
  text: string;
  to: string;
  className?: string;
}

const LinkToSubpage = ({ text, to, className }: ILinkToSubpage) => (
  <Link
    className={`${
      className ?? ""
    } p-3 border border-solid border-covid-blue text-center block w-full relative text-covid-blue`}
    to={to}
  >
    {text}
  </Link>
);

LinkToSubpage.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
};

LinkToSubpage.defaultProps = {
  className: "",
};

const MainPage = () => {
  const { t, i18n } = useTranslation("");

  return (
    <div className="main-page-container overflow-hidden relative flex flex-col">
      <MainPageTopBar />
      <TwitterNews />
      {i18n.language !== "fr" && <OurPageInNumbers />}
      <div id="what-can-you-do-on-this-page">
        <h1 className="pt-24 pb-16 text-center font-gothic text-3xl">
          {t("labels.see_our_detailed_video")}
        </h1>
        <div className="flex flex-col md:flex-row mx-auto items-top max-w-screen-lg pb-24">
          <ReactPlayer
            url="resources/Welcome-to-the-eCOVID-19-Recommendations-Map.mp4"
            style={{ margin: "0px auto" }}
            controls
          />
        </div>
        <div className="flex flex-col md:flex-row mx-auto items-top max-w-screen-lg pb-24">
          <ReactPlayer
            url="resources/What-is-the-eCOVID-RecMap-how-was-it-developed-and-how-can-I-use-it.mp4"
            style={{ margin: "0px auto" }}
            light="resources/What-is-the-eCOVID-RecMap-how-was-it-developed-and-how-can-I-use-it.png"
            controls
          />
        </div>
        <div className="flex flex-col md:flex-row mx-auto items-top max-w-screen-lg pb-24">
          <ReactPlayer
            url="resources/How-can-I-use-the-RecMap-to-tailor-recommendations-to-my-setting.mp4"
            light="resources/How-can-I-use-the-RecMap-to-tailor-recommendations-to-my-setting.png"
            style={{ margin: "0px auto" }}
            controls
          />
        </div>
        <div className="flex flex-col md:flex-row mx-auto items-top max-w-screen-lg pb-24">
          <ReactPlayer
            url="resources/Plain-language-versions-of-recommendations-how-they-are-developed.mp4"
            light="resources/Plain-language-versions-of-recommendations-how-they-are-developed.png"
            style={{ margin: "0px auto" }}
            controls
          />
        </div>
        <div className="flex flex-col md:flex-row mx-auto items-top max-w-screen-lg">
          <GuideSection sectionKey="search">{t("guideSections.search")}</GuideSection>
          <GuideSection sectionKey="list">
            <Trans i18nKey="guideSections.list">
              Check out the{" "}
              <Link className="text-covid-100 underline hover:opacity-50" to="/recommendations">
                full list of COVID-19 recommendations
              </Link>{" "}
              included (updates in process) and filter by key variables
            </Trans>
          </GuideSection>
        </div>
        <div className="flex flex-col md:flex-row mx-auto items-top max-w-screen-lg">
          <GuideSection sectionKey="grid">
            <Trans i18nKey="guideSections.grid">
              Check out the{" "}
              <Link className="text-covid-100 underline hover:opacity-50" to="/grid">
                living map of recommendations
              </Link>{" "}
              by Population and Interventions and filter by key variables
            </Trans>
          </GuideSection>
          <GuideSection sectionKey="glossary">
            <Trans i18nKey="guideSections.glossary">
              Learn more{" "}
              <Link className="text-covid-100 underline hover:opacity-50" to="/about">
                about us
              </Link>
              ,{" "}
              <Link className="text-covid-100 underline hover:opacity-50" to="/glossary">
                the terminology
              </Link>{" "}
              we are using and upcoming features
            </Trans>
          </GuideSection>
        </div>
      </div>
      <div className="what-we-are-working-on">
        <h1 className="pt-24 pb-16 text-center font-gothic text-3xl">
          {t("labels.what_we_are_currently_working_on")}
        </h1>
        <div className="max-w-screen-lg mx-auto pb-20">
          <WhatWeAreCurrentlyWorkingOn contentClassName="text-center" />
        </div>
      </div>
      <div id="adolopment-form" className="px-10 pb-24 max-w-5xl mx-auto">
        {/* <AdolopmentForm recommendationId={null} /> */}
        <h1 className="pt-16 pb-16 text-center text-3xl">
          {t("labels.gateway_to_contextualization")}
        </h1>
        <h1 className="text-whoCovid-100 text-center mb-3 text-xl">
          {t("labels.adolopment")}
          <AdolopmentInstructions />
        </h1>
        <div className="mb-2 text-center">
          <AdolopmentDescription />
        </div>
      </div>
      <div>
        <h1 className="pt-24 pb-16 text-center font-gothic text-3xl">{t("labels.mailing_list")}</h1>
        <Newsletter />
      </div>
      <Footer />
    </div>
  );
};

const MainPageTopBar = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { i18n } = useTranslation();
  const initialSearchQuery = useSearchQuery();

  const scrollTo = useCallback(
    (sectionId: string) => () => {
      return document.getElementById(sectionId)?.scrollIntoView();
    },
    []
  );

  const onSearch = useCallback(
    (query) => {
      history.push(
        `/recommendations?${new URLSearchParams(
          `lng=${i18n.language}&searchQuery=${encodeURIComponent(query)}`
        ).toString()}`
      );
    },
    [i18n.language, history]
  );

  return (
    <div className="main-page-container__top flex flex-col flex-grow  ">
      <div className="mx-auto mt-16 flex flex-row items-center">
        <img className="w-32" src={covidLogo} alt="COVID19 logo" />
        <div className="ml-16">
          <div className="app-subtitle text-3xl font-transat">{t("app.mainPageSubtitle")}</div>
          <Search
            className="font-transat block mx-auto main-page-search-container"
            inputClassName="w-full bg-white main-page"
            searchQuery={initialSearchQuery}
            onSearch={onSearch}
          />
          <div className="text-center mt-3">
            <InstructionModal triggerLabel={t("labels.search_instructions")}>
              <SearchInstructions />
            </InstructionModal>
          </div>
        </div>
      </div>
      <Tiles>
        <Tile
          icon={gridIcon}
          className="tailwind-hidden lg:block"
          title={t("labels.recommendations_map")}
        >
          <div className="mb-6 flex-grow">{t("app.main_page_tiles.grid")}</div>
          <LinkToSubpage className="w-full" to="/grid" text={t(`labels.recommendations_map`)} />
        </Tile>
        <Tile icon={listIcon} title={t("labels.full_list_of_recommendations")}>
          <div className="mb-6 flex-grow">{t("app.main_page_tiles.list_of_recommendations")}</div>
          <LinkToSubpage
            className="w-full"
            to="/recommendations"
            text={t(`labels.recommendations`)}
          />
        </Tile>
        <Tile icon={plrIcon} title={t("labels.plain_language_recommendations")}>
          <div className="mb-6 flex-grow">{t("app.main_page_tiles.list_of_plrs")}</div>
          <LinkToSubpage
            className="w-full"
            to="plain-language-recommendations"
            text={t(`labels.plain_language_recommendations`)}
          />
        </Tile>
        <Tile className="links" childrenClassName="links-container">
          <div className="flex-grow w-full">
            <AboutLinks onClick={scrollTo("adolopment-form")}>
              <img src={contextualizationIcon} alt="" className="mr-5" />
              {`${t("labels.gateway_to_contextualization")}`}
            </AboutLinks>
            <AboutLinks>
              <Link
                className="text-covid-100 hover:underline flex flex-row items-center"
                to="/guidelines-on-change-of-care"
              >
                <img src={careIcon} alt="" className="mr-5" />
                {t("labels.guidelinesOnChangeOfCare")}
              </Link>
            </AboutLinks>
            <AboutLinks onClick={scrollTo("what-can-you-do-on-this-page")}>
              <img src={videoIcon} alt="" className="mr-5" />
              {`${t("labels.see_our_detailed_video")}`}
            </AboutLinks>
          </div>
        </Tile>
      </Tiles>
      <div
        className="mx-auto w-full max-w-screen-lg mt-6 mb-12 px-3 flex-col"
        style={{ textAlign: "center" }}
      >
        <p>
          <img alt="CIHR" src={cihrLogo} className="w-1/6 mb-4" style={{ display: "inline" }} />
        </p>
        <p className="text-gray-350" dangerouslySetInnerHTML={{ __html: t("app.funding") }} />
      </div>
    </div>
  );
};

interface ITiles {
  children: ReactNode;
}

const Tiles = ({ children }: ITiles) => {
  return (
    <div className="tiles mt-24 md:flex md:flex-row mx-auto justify-center items-stretch flex-wrap">
      {children}
    </div>
  );
};

interface ITile {
  children: ReactNode;
  icon?: string;
  title?: string;
  className?: string;
  childrenClassName?: string;
}

const Tile = ({ title, icon, children, className, childrenClassName }: ITile) => {
  return (
    <div className={`mx-4 tile self-stretch ${className ?? ""}`}>
      <div className="mb-12 md:mb-4 md:w-auto w-full h-full flex flex-col max-w-sm">
        {icon && title && (
          <div className="flex flex-row items-center w-full mb-12">
            <img className="w-20 block" src={icon} alt={title} />
            <div className="text-3xl ml-5 text-covid-blue">{title}</div>
          </div>
        )}
        <div className={`flex flex-col flex-grow ${childrenClassName ?? ""}`}>{children}</div>
      </div>
    </div>
  );
};

const AboutLinks = ({ children, onClick }: { children: ReactNode; onClick?: () => void }) => (
  <div
    onClick={onClick}
    className="text-covid-100 mb-6 cursor-pointer hover:underline flex flex-row items-center link"
  >
    {children}
  </div>
);

export default MainPage;
