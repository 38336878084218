import React from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import adaptedWhiteIcon from "../../assets/adapted-white.svg";

export default function () {
  const { t } = useTranslation();
  return (
    <ReactTooltip
      id="adapted"
      effect="solid"
      className="tooltip-container"
      type="dark"
      place="bottom"
      clickable
      delayShow={300}
      delayHide={500}
      backgroundColor="#031B49"
    >
      <div className="flex items-center text-sm">
        <img src={adaptedWhiteIcon} alt="" className="mr-2" />
        {t("generalInstructions.adapted")}
      </div>
    </ReactTooltip>
  );
}
