import { includes } from "lodash";
import { useRef, useEffect } from "react";
import _ from "lodash";

// need an alias for isof
_.any = _.some;

export const isDebugMode = () => {
  // eslint-disable-next-line no-undef
  return process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "jest";
};

export const isTestEnv = () => {
  return (
    window.location.hostname === "covid19.test.evidenceprime.com" ||
    process.env.NODE_ENV === "development"
  );
};

export const linkToKnowledgebaseAdolopment = () => {
  return isTestEnv()
    ? "https://kb.covid19.test.evidenceprime.com/"
    : "https://kb.covid19.evidenceprime.ca/";
};

// TODO configure later
// export const sendReportToSentry = error => Raven.captureException(error);

export const isValidLink = (maybeUrl) => {
  try {
    const url = new URL(maybeUrl);
    return includes(["http:", "https:"], url.protocol);
  } catch (_ignored) {
    return false;
  }
};

export function useDidUpdateEffect(fn, depsList = []) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, [fn, ...depsList]);  // eslint-disable-line react-hooks/exhaustive-deps
}

export const isValidEmail = (maybeEmail) => {
  const regExp =
    /^(([A-Za-z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[A-Za-z0-9!#$%&'*+\-/=?^_`{|}~]+)*)|("[\u0020-\u007E]+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regExp.test(maybeEmail);
};
