import React from "react";
import ExternalLink from "./ExternalLink";

export const UrlRow = ({
  url,
  label,
  onlyLabel = false,
}: {
  url: string;
  label: string;
  onlyLabel?: boolean;
}) => (
  <div className="flex flex-col md:flex-row">
    <label className="font-semibold mr-2 flex-shrink-0">
      {onlyLabel ? (
        <ExternalLink className="py-1 hover:opacity-75 external-link" url={url}>
          {label}
        </ExternalLink>
      ) : (
        `${label}: `
      )}
    </label>
    {!onlyLabel && (
      <div className="break-all">
        <ExternalLink className="py-1 hover:opacity-75 external-link break-all" url={url} />
      </div>
    )}
  </div>
);
