import _ from "lodash";
import React, { FC, useMemo } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import logo from "../assets/covid_white.svg";
import gridThumbnail from "../assets/grid-thumbnail.png";
import listThumbnail from "../assets/list-thumbnail.png";
import { surveyLink } from "../lib/constants";
import { useShowBanner } from "../lib/custom_hooks";
import ExternalLink from "./common/ExternalLink";
import HamburgerMenu from "./common/HamburgerMenu";
import LanguageSwitcher from "./common/LanguageSwitcher";

interface ILinkWithTooltip {
  linkId: string;
  activeRoute: string | undefined;
}

const LinkWithTooltip: FC<ILinkWithTooltip> = ({ linkId, activeRoute }) => {
  const { t } = useTranslation();
  const navLinkClasses = useMemo(() => {
    return `top-nav-link text-white mr-4 py-1 ${
      linkId === "grid" ? "tailwind-hidden md:inline-block" : "inline-block"
    } ${activeRoute === linkId ? "active" : null}`;
  }, [activeRoute, linkId]);
  return (
    <>
      <Link data-tip="" className={navLinkClasses} to={`/${linkId}`} data-for={linkId}>
        {t(`recommendations.${linkId}`)}
      </Link>
      {!isMobile && (
        <ReactTooltip
          id={linkId}
          effect="solid"
          className="tooltip-container"
          type="dark"
          place="bottom"
          clickable
          delayShow={300}
          delayHide={500}
          backgroundColor="#031B49"
          getContent={() => (
            <div>
              <Link to={`/${linkId}`}>
                <img
                  src={linkId === "grid" ? gridThumbnail : listThumbnail}
                  alt={t(`recommendations.${linkId}`)}
                  width="200"
                />
              </Link>
            </div>
          )}
        />
      )}
    </>
  );
};

const Header = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const activeRoute = useMemo(
    () => _.first(_.split(_.trimStart(location.pathname, "/"), "/")),
    [location.pathname]
  );

  const withBanner = useShowBanner();

  return (
    <header className="z-10 relative">
      {withBanner && (
        <div
          className="webinar-info"
          dangerouslySetInnerHTML={{ __html: t("app.decemberInfo") }}
        ></div>
      )}
      <div className="h-12 p-2 sm:h-15 flex flex-row items-center shadow">
        <div className="logo flex-grow lg:flex-grow-0 flex flex-row">
          {location.pathname !== "/" && (
            <Link to="/" className="mobile-back-link block sm:hidden" />
          )}
          <Link className="flex flex-row items-center" to="/">
            <img src={logo} className="h-12 tailwind-hidden sm:block" alt="logo" />
            <img src={logo} className="h-8 block sm:hidden" alt="logo" />
            <span className="tailwind-hidden md:block ml-3 text-xl lg:text-2xl font-medium">
              {t("app.title")}
            </span>
          </Link>
        </div>
        <div className="tailwind-hidden md:block flex-grow text-center">
          <ExternalLink url={surveyLink} className="text-white font-semibold">
            {t("app.survey.header")}
          </ExternalLink>
        </div>
        <div className="flex flex-row items-center">
          <div className="mr-16">
            <nav>
              <LinkWithTooltip linkId="grid" activeRoute={activeRoute} />
              <LinkWithTooltip linkId="recommendations" activeRoute={activeRoute} />
              <LanguageSwitcher />
            </nav>
          </div>
          <HamburgerMenu />
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {};

export default Header;
