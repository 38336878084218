import React from "react";
import _ from "lodash";
import { interventionShape, populationShape, questionShape } from "../../lib/data_shapes";
import QuestionData from "./QuestionData";
import PropTypes from "prop-types";
import { TProgress } from "../../lib/types";

type TEmlLink = {
  url: string;
  label: string;
};

type TIntervention = {
  "@id": string;
  "@type": string;
  code: any[];
  name: string;
};

type TPopulation = {
  "@id": string;
  "@type": string;
  ages: string[] | { value: string[] };
  condition: string[];
  description: string;
};

type TQuestion = {
  "@id": string;
  "@type": string;
  gradeCertaintyOfEvidence: string;
  gradeStrength: string;
  intervention: { "@id": string }[];
  population: { "@id": string }[];
  recommendationIntents: string[];
};

interface IQuestionsData {
  commonStrengthAndCertainty: boolean;
  emlLinks: TEmlLink[];
  evidenceTableLink: string;
  interventions: TIntervention[];
  loveLink: string;
  populations: TPopulation[];
  questions: TQuestion[];
  progress: TProgress;
}

const QuestionsData = ({
  commonStrengthAndCertainty,
  emlLinks,
  evidenceTableLink,
  interventions,
  loveLink,
  populations,
  questions,
  progress,
}: IQuestionsData) => {
  return (
    <div>
      {_.map(questions, (question) => (
        <QuestionData
          commonStrengthAndCertainty={commonStrengthAndCertainty}
          emlLinks={emlLinks}
          evidenceTableLink={evidenceTableLink}
          interventions={interventions}
          key={question["@id"]}
          loveLink={loveLink}
          populations={populations}
          progress={progress}
          question={question}
        />
      ))}
    </div>
  );
};

QuestionsData.propTypes = {
  commonStrengthAndCertainty: PropTypes.bool,
  interventions: PropTypes.arrayOf(interventionShape),
  populations: PropTypes.arrayOf(populationShape),
  questions: PropTypes.arrayOf(questionShape),
  evidenceTableLink: PropTypes.string,
  loveLink: PropTypes.string,
  emlLinks: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

QuestionsData.defaultProps = {
  commonStrengthAndCertainty: true,
  evidenceTableLink: null,
  loveLink: null,
  emlLinks: [],
  interventions: [],
  populations: [],
  questions: [],
};

export default QuestionsData;
