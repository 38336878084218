import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useGetApi } from "../../lib/api";
import { parseTableWithHeaders } from "../../lib/helpers";
import Box from "../common/Box";
import LargeSpinner from "../common/LargeSpinner";
import SimpleSearch from "../common/SimpleSearch";
import { UrlRow } from "../common/URLRow";

type TRow = {
  title: string;
  pubDate: string;
  urlToPlainLanguageRecommendation: string;
  urlToRecMapRecommendation: string;
};

const languageOptions: { [key: number]: string } = {
  0: "english",
  1: "french",
  2: "arabic",
  3: "german",
  4: "italian",
  5: "portugese",
  6: "spanish",
};

const TableRow = ({
  currentLng,
  headers,
  row,
}: {
  currentLng: string;
  headers: string[];
  row: string[];
}) => {
  const getValue = (value: string, idx: number) => {
    switch (idx) {
      case 1:
        return <h1 className="font-transat font-semibold text-base mb-2">{value}</h1>;
      case 2:
        return (
          <div className="flex flex-col md:flex-row">
            <label className="font-semibold mr-2 flex-shrink-0">{headers[idx]}:</label>
            <div className="break-all">{value}</div>
          </div>
        );
      case 3:
      case 4:
        return !_.isEmpty(value?.trim()) ? (
          <UrlRow url={value} label={headers[idx]} onlyLabel />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <div className={`mb-5 text-xs ${currentLng}`}>
      {_.map(row, (value, idx) => (
        <div key={idx}>{getValue(value, idx)}</div>
      ))}
      <div className="gradient-separator" />
    </div>
  );
};

const PlainLanguageRecommendationsTable = ({ data }: { data: { body: string }[] }) => {
  const { t, i18n } = useTranslation();

  const [plrLng, setPlrLng] = useState(i18n.language === "fr" ? 1 : 0);
  const [headers, body] = useMemo(() => parseTableWithHeaders(data[plrLng].body), [data, plrLng]);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredRows = _.filter(body, (row: TRow) => {
    const sq = searchQuery.toLowerCase();
    return _.some(row, (cell) => cell.toLowerCase().indexOf(sq) !== -1);
  });

  const changePlrLng = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPlrLng(parseInt(e.target.value));
  };

  return (
    <div>
      <div className="mb-12  w-full flex flex-row items-center">
        <div className="flex-grow">
          <div className="additional-guidance-search-container">
            <SimpleSearch searchQuery={searchQuery} onSearch={setSearchQuery} />
          </div>
        </div>
        <div>
          <select className="language-selector" onChange={changePlrLng} value={plrLng}>
            {_.map(languageOptions, (opt, index) => (
              <option key={opt} value={index}>
                {t(`languages.${opt}`)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="plr-list">
        {_.isEmpty(filteredRows) ? (
          <div className="no-results" />
        ) : (
          _.map(filteredRows, (row: string[], index: number) => (
            <TableRow
              currentLng={languageOptions[plrLng]}
              headers={headers}
              row={row}
              key={index}
            />
          ))
        )}
      </div>
    </div>
  );
};

const PlainLanguageRecommendations = () => {
  const { t } = useTranslation();
  // @ts-ignore
  const [{ data, isLoading }] = useGetApi(`/plain-language-recommendations`);

  return (
    <div className="pb-6">
      <div className="w-full max-w-screen-lg relative mx-auto pb-6">
        <div className="w-full flex flex-row items-end justify-between mb-2">
          <h1 className={`pl-6 pt-2 text-2xl text-covid-100 font-normal`}>
            {t("labels.plainLanguageRecommendations")}
          </h1>
          <Link
            className="gradient-btn rounded px-4 py-1 hover:opacity-75"
            to="/recommendations?plainLanguageSummaryPresent=T"
          >
            {t("labels.plrOnTheRecommendationsList")}
          </Link>
        </div>
        <Box className="p-5 bg-white mt-5">
          <div className="p-4">
            {isLoading ? (
              <LargeSpinner />
            ) : _.isEmpty(data) ? null : (
              <PlainLanguageRecommendationsTable data={data} />
            )}
          </div>
        </Box>
      </div>
    </div>
  );
};

export default PlainLanguageRecommendations;
