import { CustomSelectPopover as SelectCustom } from "@evidenceprime/react-components";
import _ from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

export const LANGUAGES = ["fr", "en"] as const;
type TLanguages = typeof LANGUAGES[number];

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);

  const changeLanguage = (lang: TLanguages) => {
    i18n.changeLanguage(lang).then(() => {
      params.set("lng", lang);
      history.push({ search: params.toString() });
    });
  };

  const options = useMemo(() => _.map(LANGUAGES, (l) => ({ text: l.toUpperCase(), value: l })), []);
  const selectedLanguage = _.includes(LANGUAGES, i18n.language) ? i18n.language : "en";

  return (
    <SelectCustom
      className="language-switcher"
      listClassName="language-switcher__list"
      options={options}
      onChange={changeLanguage}
      orderedList={false}
      selected={selectedLanguage}
      signPosition="right"
      i18n={_.identity}
    />
  );
}
