import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetApi } from "../../lib/api";
import LargeSpinner from "../common/LargeSpinner";
import Guideline from "../recommendation/Guideline";

const GuidelinePage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [{ data, isLoading, isError }] = useGetApi(`/guidelines/${id}`);
  return (
    <div className="recommendation mb-6 font-transat">
      {isError && <div>{t("errors.fetching_failed")}</div>}
      {isLoading ? <LargeSpinner /> : !_.isEmpty(data) && <Guideline data={data} />}
    </div>
  );
};

export default GuidelinePage;
