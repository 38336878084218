import _ from "lodash";
import React, { useMemo } from "react";
import { TCertaintyValue } from "../../lib/types";

const totalCircles = 4;
const plusCirclesMap = {
  not_stated: 0,
  unclear: 0,
  no_included_studies: 0,
  other_explanation_in_notes: 0,
  very_low: 1,
  low: 2,
  moderate: 3,
  high: 4,
};

const CUSTOM_CERTAINTIES = ["very_low_to_low", "low_to_moderate", "moderate_to_high"];

type TCircleValues =
  | "low"
  | "very_low"
  | "moderate"
  | "high"
  | "other_explanation_in_notes"
  | "not_stated"
  | "unclear";

interface ICircles {
  value: TCircleValues;
  className?: string;
  signClassName?: string;
}

const Circles = ({ value, className, signClassName }: ICircles) => {
  const plusesNum = plusCirclesMap[value];

  const signClassNames = `quality-sign ${signClassName}`;

  const signs = useMemo(() => {
    return Array(totalCircles)
      .fill("")
      .map((_elem, idx) =>
        idx < plusesNum ? (
          <span key={idx} className={signClassNames}>
            &#x2a01;
          </span>
        ) : (
          <span key={idx} className={signClassNames}>
            &#x25ef;
          </span>
        )
      );
  }, [plusesNum, signClassNames]);

  return (
    <div style={{ height: "15px" }} className={`whitespace-no-wrap ${className}`}>
      {signs}
    </div>
  );
};

interface ICertaintyCircles {
  value: TCertaintyValue;
  signClassName?: string;
}

const CertaintyCircles = ({ value, signClassName }: ICertaintyCircles) => {
  if (_.isEmpty(value)) {
    return null;
  }
  if (_.includes(CUSTOM_CERTAINTIES, value)) {
    return (
      <div>
        {_.map(value.split("_to_"), (v) => (
          <Circles
            signClassName={signClassName}
            key={v}
            className="my-1"
            value={v as TCircleValues}
          />
        ))}
      </div>
    );
  } else {
    return <Circles signClassName={signClassName} value={value as TCircleValues} />;
  }
};

export default CertaintyCircles;
