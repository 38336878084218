import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { interventionShape, populationShape, questionShape } from "../../lib/data_shapes";
import { questionsHasCommonStrengthAndCertainty } from "../../lib/helpers";
import ExpandableCard from "./ExpandableCard";
import QuestionsData from "./QuestionsData";

const QuestionsInfo = ({
  emlLinks,
  evidenceTableLink,
  intervention,
  loveLink,
  population,
  progress,
  question,
}) => {
  const { t } = useTranslation();

  const commonStrengthAndCertainty = useMemo(
    () => questionsHasCommonStrengthAndCertainty(question),
    [question]
  );

  const questionsData = useMemo(() => {
    return (
      <QuestionsData
        commonStrengthAndCertainty={commonStrengthAndCertainty}
        emlLinks={emlLinks}
        evidenceTableLink={evidenceTableLink}
        interventions={intervention ?? []}
        loveLink={loveLink}
        populations={population ?? []}
        progress={progress}
        questions={question}
      />
    );
  }, [
    commonStrengthAndCertainty,
    emlLinks,
    question,
    intervention,
    population,
    evidenceTableLink,
    loveLink,
    progress,
  ]);

  return !commonStrengthAndCertainty ? (
    <ExpandableCard title={t("labels.comparisons")}>{questionsData}</ExpandableCard>
  ) : (
    questionsData
  );
};

QuestionsInfo.propTypes = {
  commonStrengthAndCertainty: PropTypes.bool,
  interventions: PropTypes.arrayOf(interventionShape),
  populations: PropTypes.arrayOf(populationShape),
  questions: PropTypes.arrayOf(questionShape),
  evidenceTableLink: PropTypes.string,
  loveLink: PropTypes.string,
  emlLinks: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

QuestionsInfo.defaultProps = {
  commonStrengthAndCertainty: true,
  emlLinks: [],
  evidenceTableLink: null,
  interventions: [],
  loveLink: null,
  populations: [],
  questions: [],
};

export default QuestionsInfo;
