import React from "react";
import PropTypes from "prop-types";

const GridBody = ({ onScroll, children, gridBodyRef }) => (
  <div className="grid-body" onScroll={onScroll} ref={gridBodyRef}>
    {children}
  </div>
);

GridBody.propTypes = {
  children: PropTypes.node.isRequired,
  onScroll: PropTypes.func.isRequired,
  gridBodyRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default GridBody;
