import _ from "lodash";
import React from "react";
import { Tweet } from "react-twitter-widgets";
import LargeSpinner from "../common/LargeSpinner";
import { useGetApi } from "../../lib/api";

interface ITestimonials {
  data: {
    [key: string]: {
      id: string;
      id_str: string;
    };
  };
}

const Testimonials = ({ data }: ITestimonials) => {
  return (
    <div className="pt-5 pb-12 flex flex-col sm:flex-row w-full mx-auto justify-center md:justify-between flex-wrap">
      {_.map(data, (d) => (
        <div key={d.id} className="tweet-container w-80 sm:w-60 ">
          <Tweet tweetId={d.id_str} options={{ conversation: "none", width: "auto" }} />
        </div>
      ))}
    </div>
  );
};

const TwitterNews = () => {
  // @ts-ignore TBD
  const [{ data, isLoading }] = useGetApi(`/twitter`);

  return (
    <div className="news-container">
      <div className="w-screen-lg mx-auto">
        <div>
          <button
            className="block mx-auto down-arrow"
            onClick={() => document.getElementById("twitter-testimonials")?.scrollIntoView()}
          />
        </div>
        <div id="twitter-testimonials">
          {isLoading ? <LargeSpinner /> : !_.isEmpty(data) && <Testimonials data={data} />}
        </div>
      </div>
    </div>
  );
};

export default TwitterNews;
