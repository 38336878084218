import React, { FC } from "react";
import PropTypes from "prop-types";
import spinner from "../../assets/spinner.svg";
import { useTranslation } from "react-i18next";

interface ILargeSpinner {
  height?: number;
}

const LargeSpinner: FC<ILargeSpinner> = ({ height }) => {
  const { t, ready } = useTranslation("", { useSuspense: false });
  return (
    <div className="h-full w-full spinner">
      <img
        className="mx-auto mt-24 spinner-img"
        style={{ height: `${height}px` }}
        src={spinner}
        alt={ready ? t("labels.loading") : ""}
      />
    </div>
  );
};

LargeSpinner.propTypes = {
  height: PropTypes.number,
};

LargeSpinner.defaultProps = {
  height: 80,
};

export default LargeSpinner;
