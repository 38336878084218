import PropTypes from "prop-types";
import React, { ReactNode } from "react";

const Card = ({ children, className }: { children: ReactNode; className?: string }) => {
  return <div className={`rounded-lg p-5 bg-white ${className}`}>{children}</div>;
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Card.defaultProps = {
  className: "",
};

export default Card;
